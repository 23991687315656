// extracted by mini-css-extract-plugin
export var BackToHomeDesktop = "index-module--BackToHomeDesktop--63232";
export var BackToHomeMobile = "index-module--BackToHomeMobile--c065a";
export var DesktopGutters = "index-module--DesktopGutters--cb289";
export var ErrorBlock = "index-module--ErrorBlock--14a6f";
export var GiftCard = "index-module--GiftCard--80f76";
export var GiftCardBottomRow = "index-module--GiftCardBottomRow--4195f";
export var GiftCardListItem = "index-module--GiftCardListItem--9a0e1";
export var GiftCardText = "index-module--GiftCardText--bb9f2";
export var GiftCardTopRows = "index-module--GiftCardTopRows--3dbb7";
export var Loader = "index-module--Loader--df5af";
export var PreviewButton = "index-module--PreviewButton--6e5a6";
export var SendButton = "index-module--SendButton--f1005";
export var Title = "index-module--Title--89281";